const images = [
  {
    original: '/images/IMG_0001.jpg',
    thumbnail: '/images/IMG_0001.jpg',
    description: "Test Image 1"
  },
  {
    original: '/images/IMG_0003.jpg',
    thumbnail: '/images/IMG_0003.jpg',	  
    description: "Test Image 2"
  },
  {
    original: '/images/IMG_0004.jpg',
    thumbnail: '/images/IMG_0004.jpg',	  
    description: "Test Image 3"
  },
  {
    original: '/images/IMG_0006.jpg',
    thumbnail: '/images/IMG_0006.jpg',	  
    description: "Test Image 4"
  },
  {
    original: '/images/IMG_0007.jpg',
    thumbnail: '/images/IMG_0007.jpg',
    description: "Test Image 5"
  },
  {
    original: '/images/IMG_0009.jpg',
    thumbnail: '/images/IMG_0009.jpg',
    description: "Test Image 6"
  },
];

export default images;
